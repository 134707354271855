<!-- View de Cuentas -->

<template>
  <div class="info_cta_view"
       style="align-self:start">

    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">

        <!-- Botón para abrir la ventana -->
        <v-btn 
          v-on="on"         
          v-on:click="ini_component"
          v-bind="$cfg.btra_cfg.standard"  
          :disabled="tipo_cta== 0 || cta_id== 0"
          title="Ver Información Cuenta">
            <v-icon>{{'mdi-eye'}}</v-icon>
        </v-btn>   
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Controles del Mto -->
          <div class="columna contenedor" style="width:550px;">
            <!-- Ventana de Facturas -->
            <div v-if="app==1">
              <common_Fras
                style="padding-bottom:10px"
                :padre="padre"
                :id="accionRow.id"       
                :show="showFras"
                :tip="tipo_cta">
              </common_Fras>
            </div>
            
            <v-text-field
              v-if="agencia"
              v-bind="$input"
              v-model="accionRow.afecta_name"
              label="Agencia"
              readonly>
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="accionRow.name"
              label="Cuenta"
              readonly>
            </v-text-field>

            <v-text-field
              v-if="tipo_cta==4"
              v-bind="$input"
              v-model="accionRow.name_afecta_f"
              label="Funeraria asignada"
              readonly>
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="accionRow.dire"
              label="Dirección"
              readonly>
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="accionRow.name_loc"
              label="Origen"
              readonly>
            </v-text-field>

            <div class="conflex">
              <v-text-field
                v-bind="$input"
                v-model="accionRow.cp"
                label="Código Postal"
                readonly>
              </v-text-field>

              <v-text-field
                v-bind="$input"
                v-model="accionRow.cif"
                label="Cif"
                readonly>
              </v-text-field>
            </div>      

            <div class="conflex">
              <v-text-field
                v-bind="$input"
                v-model="accionRow.tlf1"
                label="Teléfono"
                readonly>
              </v-text-field>

              <v-text-field
                v-bind="$input"
                v-model="accionRow.fax1"
                label="Fax"
                readonly>
              </v-text-field>
            </div>           

            <v-text-field
              v-bind="$input"
              v-model="accionRow.email1"
              label="Email"
              readonly>
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="accionRow.admonemail"
              label="Email Admon"
              readonly>
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="accionRow.ctabanco"
              label="Cuenta Bancaria"
              readonly>
            </v-text-field>   

            <v-textarea
              v-bind="$textarea"
              v-model="accionRow.obs"
              label="Observaciones"
              no-resize
              rows="3"
              readonly>            
            </v-textarea>  
          </div>   
      
          
        </v-sheet>
      </div>
    </v-dialog>    
  </div>
</template>


<script>
 
  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const common_Fras = () => plugs.groute("common_Fras.vue", "comp");
  
  export default {
    mixins: [mixinCtrls],
    components: {  
      base_Header,
      common_Fras    
    },

    props: {      
      padre: { type:String, default: '' }, 
      tipo_cta: { type:[Number, String], default: 0},
      cta_id: { type:[Number, String], default: 0},      
      disparo: { type:Boolean, default:false },
      agencia: { type:Boolean, default:false },
      showFras: { type:String, default:"1" },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'',
        stName:'stMinfo_cta',
        accionRow: {},

        dialog:false,       
      };
    },


    created() {
      this.ini_data();
      this.ini_component();
    },
   
  
    methods: {
      ini_data(){
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Información Cuenta"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));       
      },


      //
      async ini_component() {
        if (this.tipo_cta== 0 || this.cta_id==0) return;

        let args = { tipo:'fnc', accion: 'expediente', fn_args: { 
          accion:'datos_cta',
          tipo_cta:this.tipo_cta,
          cta_id:this.cta_id
        }};
        console.log('args ini_component INFO_CTA: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });   
        console.log('apiResult ini_component INFO_CTA: ', apiResult);
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          this.dialog=false
          return;
        }

        this.accionRow= apiResult.r[0];
      }

    }
  };
</script>
